export default class
{
    static up()
    {
        return [
            {
                "url": "https://www.youtube.com",
                "image_name": "Youtube.png"
            },
            {
                "url": "https://mail.google.com/mail/u/0",
                "image_name": "Gmail.png"
            },
            {
                "url": "https://drive.google.com/drive/u/0",
                "image_name": "Drive.png"
            },
            {
                "url": "https://calendar.google.com/calendar/u/0/r",
                "image_name": "Calendar.png"
            },
            {
                "url": "https://photos.google.com/u/0",
                "image_name": "Photos.png"
            },
            {
                "url": "https://ssnovohradska.edupage.org/user/?c2fa=1",
                "image_name": "Edupage.png"
            },
            {
                "url": "https://classroom.google.com/u/2/",
                "image_name": "Classroom.png"
            },
            {
                "url": "https://www.netflix.com/browse",
                "image_name": "Netflix.png"
            }
        ];
    }

    static down()
    {
        return [
            {
                "url": "https://wezeo.wgrid.app/home",
                "image_name": "wGrid.png"
            },
            {
                "url": "https://report.wezeo.team/backend/appuser/user/users/update/122",
                "image_name": "Wezeo.png"
            },
            {
                "url": "https://wezeo.cenntria.com/app/salaries",
                "image_name": "Cenntria.png"
            },
            {
                "url": "https://web.postman.co/me?",
                "image_name": "Postman.png"
            },
            {
                "url": "https://gitlab.com",
                "image_name": "Gitlab.png"
            },
            {
                "url": "https://github.com",
                "image_name": "Github.png"
            },
            {
                "url": "https://chatgpt.com/",
                "image_name": "ChatGPT.png"
            }
        ];
    }
}
